import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, theme, Typography } from "antd";
import logo from "../../assets/images/smartiva.png";
import { useLocation, useNavigate } from "react-router";
import AppContext from "../../store/app.context";
import {
	PiBookmarksDuotone,
	PiBuildingsDuotone,
	PiCreditCardDuotone,
	PiDotsNineDuotone,
	PiHouseDuotone,
	PiLockOpenDuotone,
	PiPackageDuotone,
	PiReceiptDuotone,
	PiUsersDuotone,
	PiWhatsappLogoDuotone,
} from "react-icons/pi";

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
	overflow: "auto",
	height: "100vh",
	position: "fixed",
	insetInlineStart: 0,
	top: 0,
	bottom: 0,
	scrollbarWidth: "thin",
	scrollbarColor: "#eaeaea transparent",
	scrollbarGutter: "0px",
	boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
	zIndex: 15,
};

const masterMenu = [
	{
		key: "dashboard",
		icon: <PiHouseDuotone />,
		label: "Dasbor",
	},
	{
		key: "modules",
		icon: <PiPackageDuotone />,
		label: "Modul",
	},
	{
		key: "users",
		icon: <PiUsersDuotone />,
		label: "Pengguna",
	},
	{
		key: "sessions",
		icon: <PiLockOpenDuotone />,
		label: "Sesi Aktif",
	},
	{
		key: "schools",
		icon: <PiBuildingsDuotone />,
		label: "Sekolah",
	},
	{
		key: "bills",
		icon: <PiReceiptDuotone />,
		label: "Tagihan",
	},
	{
		key: "subjects",
		icon: <PiBookmarksDuotone />,
		label: "Mata Pelajaran",
	},
	{
		key: "cards",
		icon: <PiCreditCardDuotone />,
		label: "Kartu Terintegrasi",
	},
	{
		key: "whatsapp",
		icon: <PiWhatsappLogoDuotone />,
		label: "Whatsapp",
		children: [
			{
				key: "whatsapp/broadcasts",
				label: "Broadcast",
			},
		],
	},
	{
		key: "other",
		icon: <PiDotsNineDuotone />,
		label: "Lain-lain",
		children: [
			{
				key: "settings",
				// icon: <HiCog6Tooth />,
				label: "Pengaturan",
			},
		],
	},
];

function CustomSider({
	collapsed,
	setCollapsed,
	query,
}: {
	collapsed: boolean;
	setCollapsed: any;
	query: string;
}) {
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");

	const [matchedKeys, setMatchedKeys] = useState<string[]>([]);
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [menu, setMenu] = useState<any[]>(context.role === "MASTER" ? masterMenu : []);

	const {
		token: { colorPrimary, colorPrimaryText },
	} = theme.useToken();

	useEffect(() => {
		setMenu(context.role === "MASTER" ? masterMenu : []);
		setDefaultOpenKey();
		setMatchedKeys([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.role]);

	useEffect(() => {
		handleSearchMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const setDefaultOpenKey = () => {
		const activeKey = page.split("/")[1];
		if (activeKey) {
			const openKey = menu.find((item) =>
				item.children?.some((child: any) => child.key === activeKey)
			)?.key;

			if (openKey) {
				setOpenKeys([openKey]);
			}
		}
	};

	const handleSearchMenu = () => {
		if (!query) {
			setMatchedKeys([]);
			setDefaultOpenKey();
			setMenu(context.role === "MASTER" ? masterMenu : []);
			return;
		}
		// hightlight menu where the label contains the query
		const item = context.role === "MASTER" ? masterMenu : [];
		const matchedKeys: string[] = [];
		const openKeys: string[] = [];
		if (context.role === "MASTER") {
			masterMenu?.forEach((item) => {
				if (item?.label.toLowerCase().includes(query.toLowerCase())) {
					matchedKeys.push(item.key);
				}
				if (item.children) {
					item.children.forEach((child) => {
						if (child.label.toLowerCase().includes(query.toLowerCase())) {
							matchedKeys.push(child.key);
							openKeys.push(item.key);
						}
					});
				}
			});
		}

		// set label to bold where the label contains the query
		const newMenu = item.map((item) => {
			if (item.label.toLowerCase().includes(query.toLowerCase())) {
				const matchLabelIndex = item.label.toLowerCase().indexOf(query.toLowerCase());
				const beforeMatch = item.label.slice(0, matchLabelIndex);
				const match = item.label.slice(matchLabelIndex, matchLabelIndex + query.length);
				const afterMatch = item.label.slice(matchLabelIndex + query.length);

				return {
					...item,
					label: (
						<span key={item.key}>
							{beforeMatch}
							<Typography.Text strong type="danger">
								{match}
							</Typography.Text>
							{afterMatch}
						</span>
					),
				};
			}
			if (item.children) {
				const children = item.children.map((child) => {
					if (child.label.toLowerCase().includes(query.toLowerCase())) {
						const matchLabelIndex = child.label.toLowerCase().indexOf(query.toLowerCase());
						const beforeMatch = child.label.slice(0, matchLabelIndex);
						const match = child.label.slice(matchLabelIndex, matchLabelIndex + query.length);
						const afterMatch = child.label.slice(matchLabelIndex + query.length);

						return {
							...child,
							label: (
								<span key={child.key}>
									{beforeMatch}
									<Typography.Text strong type="danger">
										{match}
									</Typography.Text>
									{afterMatch}
								</span>
							),
						};
					}
					return child;
				});
				return {
					...item,
					children,
				};
			}
			return item;
		});

		setMenu(newMenu);
		setOpenKeys(openKeys);
		setMatchedKeys(matchedKeys);
	};

	return (
		<Sider
			theme="light"
			style={siderStyle}
			width={248}
			collapsed={collapsed}
			onCollapse={(value) => setCollapsed(value)}>
			<div
				style={{
					height: 40,
					// backgroundColor: colorBgContainer,
					// borderRadius: borderRadiusLG,
					margin: "16px",
					alignContent: "center",
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
				}}>
				<img
					src={logo}
					alt="logo"
					style={{
						height: "100%",
						objectFit: "contain",
					}}
				/>
				{!collapsed && (
					<div
						style={{
							marginLeft: 8,
							display: "flex",
							flexDirection: "column",
						}}>
						<Typography.Text
							style={{
								margin: 0,
								whiteSpace: "nowrap",
								color: colorPrimaryText,
								fontWeight: "bold",
							}}>
							Smartiva
						</Typography.Text>
						<Typography.Text
							style={{
								fontSize: 8,
								fontWeight: "lighter",
								color: colorPrimaryText,
							}}>
							powered by Codeiva
						</Typography.Text>
					</div>
				)}
			</div>
			<Menu
				style={{
					marginTop: 16,
					padding: "0 12px",
				}}
				theme="light"
				mode="inline"
				openKeys={openKeys}
				selectedKeys={[page === "" ? "dashboard" : page.split("/")[1]]}
				onOpenChange={(keys) => setOpenKeys(keys as string[])}
				items={menu.filter(
					(item) =>
						matchedKeys.includes(item.key) ||
						item.children?.some((child: any) => matchedKeys.includes(child.key)) ||
						!query
				)}
				onClick={({ key }) => {
					const role = context.role === "MASTER" ? "master" : "other";
					navigate(`/${role}/${key}`);
				}}
			/>
			<div
				style={{
					height: 16,
				}}
			/>
		</Sider>
	);
}

export default CustomSider;
