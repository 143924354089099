/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { App, Button, Card, Dropdown, Space, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import { requestGetSessionList, requestTerminateSession } from "../../../services/master.service";
import { PiXDuotone } from "react-icons/pi";

const columns = [
	{
		title: "Pengguna",
		dataIndex: "user",
		key: "user",
		render: (text, record) => {
			return (
				<Space direction="horizontal">
					<Typography.Text>{record.user.name}</Typography.Text>
					<Tag>
						<Typography.Text strong>{record.user.email}</Typography.Text>
					</Tag>
				</Space>
			);
		},
	},
	{
		title: "Klien",
		dataIndex: "client",
		key: "client",
		render: (text, record) => {
			return (
				<Space direction="horizontal">
					{record?.client?.code && <Typography.Text>{record?.client?.code}</Typography.Text>}
					{record?.client?.version && (
						<Tag>
							<Typography.Text>{record?.client?.version}</Typography.Text>
						</Tag>
					)}
				</Space>
			);
		},
	},
	{
		title: "IP",
		dataIndex: "ip",
		key: "ip",
		render: (text, record) => {
			return <Typography.Text>{record?.device.ip}</Typography.Text>;
		},
	},
	{
		title: "Perangkat",
		dataIndex: "device",
		key: "device",
		render: (text, record) => {
			return (
				<Space direction="horizontal">
					{record.device?.vendor && record.device?.os && (
						<Typography.Text>
							{record.device.vendor} ({record.device.os})
						</Typography.Text>
					)}
					{record.device?.model && (
						<Tag>
							<Typography.Text strong>{record.device?.model?.slice(0, 12)}</Typography.Text>
						</Tag>
					)}
				</Space>
			);
		},
	},
	{
		title: "Penyegaran",
		dataIndex: "refreshedAt",
		key: "refreshedAt",
		render: (text, record) => {
			// the data is 1739438007957, convert to date
			return dayjs(record.device.refreshedAt).format("DD MMMM YYYY HH:mm");
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "terminate",
		label: "Akhiri",
		icon: <PiXDuotone />,
		danger: true,
	},
];

const SessionPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { message, notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		"session.device.refreshedAt": "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchSessionList();
		// eslint-disable-next-line no-use-before-define
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [sessions, setSessions] = useState([]);
	const [mappedSessions, setMappedSessions] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [session, setSession] = useState(null);

	const fetchSessionList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetSessionList({
			page: page,
			limit: limit,
			order: tempSorter ?? "session.device.refreshedAt:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setSessions(response.data.data);
				updateMappedSessions(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedSessions = (data) => {
		setMappedSessions(
			data.map((d) => ({
				key: d._id,
				user: d.session?.user,
				client: d.session?.client,
				device: d.session?.device,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "terminate") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan mengakhiri sesi ini? Tindakan ini tidak dapat dibatalkan. Pengguna akan keluar dari device ini.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												requestTerminateSession(d._id)
													.then((response) => {
														message.success(response.data.message);

														fetchSessionList();
													})
													.catch((error) => {
														if (error.response && error.response.data) {
															notification["error"]({
																message: "Kesalahan!",
																description: error.response.data.message,
															});
														} else {
															notification["error"]({
																message: "Kesalahan!",
																description: error.message,
															});
														}
													})
													.finally(() => {
														setLoading(false);
													});
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<Card
				bordered={false}
				title="Daftar Pengguna"
				extra={
					<Space>
						<SearchInput placeholder="No. induk atau nama guru" onSearch={handleSearch} />
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedSessions}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default SessionPage;
