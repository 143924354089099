import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { App, Button, Card, Dropdown, Space, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { requestAcceptBill, requestGetBillList } from "../../../services/master.service";
import dayjs from "dayjs";
import { HiOutlineCheck } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		title: "No.",
		dataIndex: "number",
		key: "number",
		sorter: true,
		render: (text, record) => {
			return (
				<Space direction="vertical" size={0}>
					<Typography.Text
						copyable
						style={{
							fontFamily: "monospace",
							fontWeight: "bold",
						}}>
						{record.number}
					</Typography.Text>
					<Typography.Text type="secondary">
						{dayjs(record.issuedAt).format("DD MMMM YYYY")}
					</Typography.Text>
				</Space>
			);
		},
	},
	{
		title: "Sekolah",
		dataIndex: "bill",
		key: "bill",
		sorter: true,
		render: (text, record) => {
			return (
				<Space direction="vertical" size={0}>
					<Typography.Text>{record.school.name}</Typography.Text>
				</Space>
			);
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		sorter: true,
		render: (text, record) => {
			let status =
				record.status === "PAID" ? "Lunas" : record.status === "CREATED" ? "Belum lunas" : "Gagal";
			let color = "geekblue";
			if (record.status === "CREATED") {
				color = "volcano";
			} else if (record.status === "PAID") {
				color = "green";
			}
			return <Tag color={color}>{status}</Tag>;
		},
	},
	{
		title: "Jatuh Tempo",
		dataIndex: "dueAt",
		key: "dueAt",
		sorter: true,
		render: (text, record) => {
			return dayjs(text).format("DD MMMM YYYY");
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	// {
	// 	key: "show",
	// 	label: "Lihat",
	// 	icon: <HiOutlineEye />,
	// },
	{
		key: "accept",
		label: "Terima",
		icon: <HiOutlineCheck />,
	},
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <HiOutlineTrash />,
	// 	danger: true,
	// },
];

const BillPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { message, notification, modal } = App.useApp();

	useEffect(() => {
		document.title = "Ruang HQ | Tagihan";
	}, []);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchBillList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	// eslint-disable-next-line no-unused-vars
	const [bills, setBills] = useState([]);
	const [mappedBills, setMappedBills] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const fetchBillList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetBillList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setBills(response.data.data);
				updateMappedBill(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleAcceptButton = (id) => {
		requestAcceptBill(id)
			.then((response) => {
				message.success("Pembayaran berhasil diterima");
				fetchBillList();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const updateMappedBill = (data) => {
		setMappedBills(
			data.map((d) => ({
				key: d._id,
				number: d.number,
				school: d.school,
				issuedAt: d.issuedAt,
				dueAt: d.dueAt,
				status: d.status,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "accept") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content: "Apakah Anda yakin ingin menerima pembayaran ini?",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleAcceptButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<Card
				bordered={false}
				title="Daftar Sekolah"
				extra={
					<Space>
						<SearchInput placeholder="Nama sekolah" onSearch={handleSearch} />
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedBills}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default BillPage;
