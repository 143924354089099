import { Card, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import { HiAcademicCap, HiArrowDown, HiBriefcase, HiChartBar } from "react-icons/hi2";

function DashboardPage() {
	useEffect(() => {
		document.title = "Ruang HQ | Dasbor";
	}, []);

	const { Title } = Typography;

	const count = [
		{
			today: "Siswa",
			title: "N/A",
			persent: "",
			icon: HiAcademicCap,
			bnb: "bnb2",
		},
		{
			today: "Guru",
			title: "N/A",
			persent: "",
			icon: HiBriefcase,
			bnb: "bnb2",
		},
		{
			today: "Kelas",
			title: "N/A",
			persent: "",
			icon: HiChartBar,
			bnb: "bnb2",
		},
		{
			today: "Uang masuk",
			title: "N/A",
			persent: "",
			icon: HiArrowDown,
			bnb: "redtext",
		},
	];

	return (
		<Row gutter={[16, 0]}>
			{count.map((c, index) => (
				<Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
					<Card bordered={false}>
						<div>
							<Row align="middle" gutter={[24, 0]}>
								<Col xs={18}>
									<span>{c.today}</span>
									<Title level={3}>
										{c.title} <small>{c.persent}</small>
									</Title>
								</Col>
								<Col xs={6}>
									<div>
										<c.icon />
									</div>
								</Col>
							</Row>
						</div>
					</Card>
				</Col>
			))}
		</Row>
	);
}

export default DashboardPage;
