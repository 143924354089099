/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Dropdown,
	Flex,
	message,
	Progress,
	Space,
	Table,
	Tag,
	Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { HiOutlinePlay, HiOutlineStop, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../../components/SearchInput";
import { useSearchParams } from "react-router-dom";
import {
	requestGetWhatsappBroadcastList,
	requestStartWhatsappBroadcast,
	requestStopWhatsappBroadcast,
} from "../../../../services/master.service";
import BroadcastFormModal from "./components/BroadcastFormModal";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Description",
		dataIndex: "description",
		key: "description",
	},
	{
		title: "Progress",
		dataIndex: "progress",
		key: "progress",
		render: (text, record) => {
			let total = record.recipients.length;
			let sent = record.recipients.filter((r) => r.status === "SENT").length;
			let sentPercent = (sent / total) * 100;
			let failed = record.recipients.filter((r) => r.status === "FAILED").length;
			let failedPercent = (failed / total) * 100;

			return (
				<Tooltip title={`${sent} terkirim, ${failed} gagal`}>
					{/* {record.status === "PROCESSING" ? (
						<Flex>
							<Progress
								percent={sent + failed}
								success={{ percent: sent, strokeColor: "#52c41a" }}
								strokeColor="#f5222d"
								size="small"
								status="active"
							/>
						</Flex>
					) : record.status === "STOPPED" ? (
						<Flex>
							<Progress
								percent={sent + failed}
								success={{ percent: sent, strokeColor: "#52c41a" }}
								strokeColor="#f5222d"
								size="small"
								status="exception"
							/>
						</Flex>
					) : record.status === "COMPLETED" ? (
						<Flex>
							<Progress
								percent={sent + failed}
								success={{ percent: sent, strokeColor: "#52c41a" }}
								strokeColor="#f5222d"
								size="small"
								status="success"
							/>
						</Flex>
					) : (
						<Flex>
							<Progress
								percent={sent + failed}
								success={{ percent: sent, strokeColor: "#52c41a" }}
								strokeColor="#f5222d"
								size="small"
								status="normal"
							/>
						</Flex>
					)} */}
					<Flex>
						<Progress
							percent={sentPercent + failedPercent}
							success={{ percent: sentPercent, strokeColor: "#52c41a" }}
							strokeColor="#f5222d"
							size="small"
							showInfo={false}
						/>
					</Flex>
				</Tooltip>
			);
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (text, record) => {
			return (
				<Tag
					color={
						record.status === "PROCESSING"
							? "processing"
							: record.status === "STOPPED"
							? "error"
							: record.status === "COMPLETED"
							? "success"
							: "default"
					}>
					{record.status}
				</Tag>
			);
		},
		sorter: true,
		filters: [
			{ text: "Draf", value: "DRAFT" },
			{ text: "Proses", value: "PROCESSING" },
			{ text: "Berhenti", value: "STOPPED" },
			{ text: "Selesai", value: "COMPLETED" },
		],
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "start",
		label: "Mulai",
		icon: <HiOutlinePlay />,
	},
	{
		key: "stop",
		label: "Hentikan",
		icon: <HiOutlineStop />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const WhatsappBroadcastPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { notification, modal } = App.useApp();

	useEffect(() => {
		document.title = "Ruang HQ | WhatsApp | Broadcast";
	}, []);

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchBroadcastList();
		// eslint-disable-next-line no-use-before-define
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [broadcasts, setBroadcasts] = useState([]);
	const [mappedBroadcasts, setMappedBroadcasts] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isBroadcastFormVisible, setBroadcastFormVisible] = useState(false);

	const fetchBroadcastList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetWhatsappBroadcastList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setBroadcasts(response.data.data);
				updateMappedBroadcasts(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedBroadcasts = (data) => {
		setMappedBroadcasts(
			data.map((d) => ({
				key: d._id,
				...d,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "start") {
										// modal.confirm({
										// 	title: "Mulai Broadcast",
										// 	content: "Apakah anda yakin ingin memulai broadcast ini?",
										// 	onOk: () => {
										// 		handleStarteButton(d._id);
										// 	},
										// });
										handleStarteButton(d._id);
									} else if (e.key === "stop") {
										// modal.confirm({
										// 	title: "Hentikan Broadcast",
										// 	content: "Apakah anda yakin ingin menghentikan broadcast ini?",
										// 	onOk: () => {
										// 		handleStopButton(d._id);
										// 	},
										// });
										handleStopButton(d._id);
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setBroadcastFormVisible(true);
	};

	const handleStarteButton = (id) => {
		setLoading(true);
		requestStartWhatsappBroadcast(id)
			.then((response) => {
				if (response.data.code === 200) {
					message.success("Broadcast berhasil dimulai!");

					fetchBroadcastList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleStopButton = (id) => {
		setLoading(true);
		requestStopWhatsappBroadcast(id)
			.then((response) => {
				if (response.data.code === 200) {
					message.success("Broadcast berhasil dihentikan!");

					fetchBroadcastList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseBroadcastForm = () => {
		setBroadcastFormVisible(false);
	};

	const handleOnSuccessBroadcastForm = () => {
		setBroadcastFormVisible(false);
		fetchBroadcastList();
	};

	return (
		<>
			<BroadcastFormModal
				isLoading={isLoading}
				isVisible={isBroadcastFormVisible}
				onLoading={(v) => setLoading(v)}
				onClose={handleOnCloseBroadcastForm}
				onSuccess={handleOnSuccessBroadcastForm}
			/>
			<Card
				bordered={false}
				title="Daftar Broadcast Whatsapp"
				extra={
					<Space>
						<SearchInput placeholder="Nama nama broadcast" onSearch={handleSearch} />
						<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
							Tambah
						</Button>
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedBroadcasts}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default WhatsappBroadcastPage;
